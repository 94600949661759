import React from 'react'

import Icon from '@c/icon'
import ProjectImage from '@/components/project-image'

const Zeo = ({
  title,
  logoSketchTitle,
  logoSketchDescription,
  logoSketchKitUrl,
  logoSketchImage,
  downloadKitButtonText,

  // symbols
  symbolsTitle,
  solidSymbolTitle,
  solidSymbol,
  lineSymbolTitle,
  lineSymbol,
  logoTitle,
  logo,
  logoAsset,

  // brand colors
  brandColorsTitle,
  crimsonTitle,
  midnightTitle,
  cloudTitle,
  zeoLogoDark,
}) => (
  <section>
    <div className="row">
      <div className="col-12">
        <h1 className="h2">{title}</h1>
      </div>
    </div>
    {/* sketch */}
    <div className="row align-items-start mt-60 mt-lg-80 align-items-center align-items-lg-start">
      <div id="zeo-assets" className="col-6 col-lg-4">
        <h4>{logoSketchTitle}</h4>
        <div
          className="mt-40"
          dangerouslySetInnerHTML={{ __html: logoSketchDescription }}
        />
        <a href={logoSketchKitUrl} className="btn btn--line mt-32" download>
          {downloadKitButtonText}
          <Icon name="bottom" className="ml-24 c-red-dark" />
        </a>
      </div>
      <div className="col-6 col-lg-8 ta-lg-right">
        {logoSketchImage && <ProjectImage image={logoSketchImage} />}
      </div>
    </div>
    {/* symbol */}
    <div className="row mt-60 mt-lg-80">
      <div className="col-12">
        <h4>{symbolsTitle}</h4>
      </div>
      <div className="col-12 mt-40">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="border-box p-24">
              <h5>{solidSymbolTitle}</h5>
              <figure className="mt-24 ta-center">
                {solidSymbol && <ProjectImage image={solidSymbol} />}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-3 mt-32 mt-lg-0">
            <div className="border-box p-24">
              <h5>{lineSymbolTitle}</h5>
              <figure className="mt-24 ta-center">
                {lineSymbol && <ProjectImage image={lineSymbol} />}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-32 mt-lg-0">
            <div className="border-box p-24">
              <h5>{logoTitle}</h5>
              <figure className="mt-24 ta-center">
                {logo && <ProjectImage height={68} image={logoAsset} />}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* colors */}
    <div className="row mt-60 mt-lg-80">
      <div className="col-12">
        <h4>{brandColorsTitle}</h4>
      </div>
      <div className="col-12 mt-40">
        <div className="row no-gutters">
          <div className="col-4">
            <div className="c-dark-60 d-none d-lg-block">
              <p>{crimsonTitle}</p>
              <p>C: 0 M: 100 Y: 79 K: 23</p>
              <p>R: 196 G: 0 B: 42</p>
            </div>
            <div className="bg-red c-white mt-lg-16 p-24 d-flex align-items-center justify-content-center">
              #CC0A4D
            </div>
          </div>
          <div className="col-4">
            <div className="c-dark-60 d-none d-lg-block">
              <p>{midnightTitle}</p>
              <p>C: 0 M: 100 Y: 79 K: 23</p>
              <p>R: 196 G: 0 B: 42</p>
            </div>
            <div className="bg-dark c-white mt-lg-16 p-24 d-flex align-items-center justify-content-center">
              #012535
            </div>
          </div>
          <div className="col-4">
            <div className="c-dark-60 d-none d-lg-block">
              <p>{cloudTitle}</p>
              <p>C: 0 M: 100 Y: 79 K: 23</p>
              <p>R: 196 G: 0 B: 42</p>
            </div>
            <div className="bg-cloud c-text mt-lg-16 p-24 d-flex align-items-center justify-content-center">
              #d2d5db
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-40">
        <div className="border-box bg-dark py-60 d-flex align-items-center justify-content-center">
          {zeoLogoDark && <ProjectImage height={68} image={zeoLogoDark} />}
        </div>
      </div>
    </div>
  </section>
)

export default Zeo
