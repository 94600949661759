import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import Icon from '@c/icon'

import Zeo from '@v/brand-assets/zeo'
import Events from '@v/brand-assets/events'
import Tools from '@v/brand-assets/tools'
import DatoCMSModel from '../models/DatoCMSModel'

const getOffsetTop = element => {
  let offsetTop = 0

  while (element) {
    offsetTop += element.offsetTop
    element = element.offsetParent
  }

  return offsetTop
}

function scrollDown(value) {
  const elemTop = getOffsetTop(document.getElementById(value))
  const menuHeight = document.querySelector('header.SiteHeader').scrollHeight

  console.log('elemTop, menuHeight:', { elemTop, menuHeight })

  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: elemTop - menuHeight
  })
}

const Page = ({ data, pageContext }) => {
  const { page } = data
  const title = page.title
  const model = new DatoCMSModel(page)
  const jumplinkList = [
    { title: page.logoSketchJumplinkTitle, href: '#zeo-assets' },
    { title: page.eventsJumplinkTitle, href: '#event-assets' },
    { title: page.toolsJumplinkTitle, href: '#tool-assets' }
  ]

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* page */}
      <div className="container">
        <div className="row">
          {/* SIDEBAR */}
          <div className="col-12 col-lg-4">
            <div className="d-none d-lg-flex flex-column u-sticky">
              <p className="fs-x-small c-soft-red">{page.jumpLinksTitle}</p>
              {jumplinkList.map((link, index) => {
                return (
                  <a
                    className="brand-assets-jumplink d-flex align-items-center py-8 my-8 border-b-white"
                    href={link.href}
                    onClick={ev => {
                      ev.preventDefault()
                      scrollDown(link.href.slice(1)) // remove the "#" part
                    }}
                    key={index}
                  >
                    <p className="fs-normal">{link.title}</p>
                    <Icon className="right-icon ml-auto" />
                  </a>
                )
              })}
            </div>
          </div>

          {/* CONTENT */}
          <div className="col-12 col-lg-8">
            <Zeo
              title={page.contentTitle}
              downloadKitButtonText={page.downloadKitButtonText}
              logoSketchTitle={page.logoSketchTitle}
              logoSketchDescription={page.logoSketchDescription}
              logoSketchKitUrl={page.logoSketchKitUrl}
              logoSketchImage={page.logoSketchImage}
              logoSketchImageAsset={page.logoSketchImageAsset}
              // symbols
              symbolsTitle={page.symbolsTitle}
              solidSymbolTitle={page.solidSymbolTitle}
              solidSymbol={page.solidSymbol}
              solidSymbolAsset={page.solidSymbolAsset}
              lineSymbolTitle={page.lineSymbolTitle}
              lineSymbol={page.lineSymbol}
              lineSymbolAsset={page.lineSymbolAsset}
              logoTitle={page.logoTitle}
              logo={page.logo}
              logoAsset={page.logoAsset}
              // brand colors
              brandColorsTitle={page.brandColorsTitle}
              crimsonTitle={page.crimsonTitle}
              midnightTitle={page.midnightTitle}
              cloudTitle={page.cloudTitle}
              zeoLogoDark={page.zeoLogoDark}
              zeoLogoDarkAsset={page.zeoLogoDarkAsset}
            />

            <Events
              downloadKitButtonText={page.downloadKitButtonText}
              // events
              eventsTitle={page.eventsTitle}
              eventsKitUrl={page.eventsKitUrl}
              digitalzoneLogoSymbolsTitle={page.digitalzoneLogoSymbolsTitle}
              eventsSolidSymbolTitle={page.eventsSolidSymbolTitle}
              eventsSolidSymbol={page.eventsSolidSymbol}
              eventsSolidSymbolAsset={page.eventsSolidSymbolAsset}
              eventsLineSymbolTitle={page.eventsLineSymbolTitle}
              eventsLineSymbol={page.eventsLineSymbol}
              eventsLineSymbolAsset={page.eventsLineSymbolAsset}
              eventsLogoTitle={page.eventsLogoTitle}
              eventsLogo={page.eventsLogo}
              eventsLogoAsset={page.eventsLogoAsset}
              // digitalzone meetup
              digitalzoneMeetupLogoTitle={page.digitalzoneMeetupLogoTitle}
              digitalzoneMeetupLogoText={page.digitalzoneMeetupLogoText}
              digitalzoneMeetupLogo={page.digitalzoneMeetupLogo}
              digitalzoneMeetupLogoAsset={page.digitalzoneMeetupLogoAsset}
              digitalzoneMeetupLogoDarkText={page.digitalzoneMeetupLogoDarkText}
              digitalzoneMeetupLogoDark={page.digitalzoneMeetupLogoDark}
              digitalzoneMeetupLogoDarkAsset={
                page.digitalzoneMeetupLogoDarkAsset
              }
            />

            <Tools
              downloadKitButtonText={page.downloadKitButtonText}
              // tools
              toolsTitle={page.toolsTitle}
              toolsTitle2={page.toolsTitle2}
              toolsKitUrl={page.toolsKitUrl}
              tools={page.tools}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query BrandAssetsPageQuery($locale: String) {
    page: datoCmsBrandAsset(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      contentTitle
      description

      downloadKitButtonText

      jumpLinksTitle

      # logo
      logoSketchTitle
      logoSketchJumplinkTitle
      logoSketchDescription
      logoSketchKitUrl
      logoSketchImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # symbols
      symbolsTitle
      solidSymbolTitle
      solidSymbol {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      lineSymbolTitle
      lineSymbol {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
      logoTitle
      logo {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # brand colors
      brandColorsTitle
      crimsonTitle
      midnightTitle
      cloudTitle
      zeoLogoDark {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # events
      eventsTitle
      eventsJumplinkTitle
      eventsKitUrl
      digitalzoneLogoSymbolsTitle
      eventsSolidSymbolTitle
      eventsSolidSymbol {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      eventsLineSymbolTitle
      eventsLineSymbol {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      eventsLogoTitle
      eventsLogo {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # digitalzone meetup
      digitalzoneMeetupLogoTitle
      digitalzoneMeetupLogoText
      digitalzoneMeetupLogo {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
      digitalzoneMeetupLogoDarkText
      digitalzoneMeetupLogoDark {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # tools
      toolsTitle
      toolsJumplinkTitle
      toolsTitle2
      toolsKitUrl
      tools {
        id
        title
        logo {
          alt
          asset {
            localImage {
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`
