import React from 'react'

import Icon from '@c/icon'
import ProjectImage from '@/components/project-image'

const Zeo = ({
  downloadKitButtonText,

  // tools
  toolsTitle,
  toolsTitle2,
  toolsKitUrl,
  tools
}) => (
  <section>
    <div className="row mt-120 align-items-center">
      <div id="tool-assets" className="col-12 col-lg-6">
        <h2>{toolsTitle}</h2>
      </div>
      <div className="col-12 col-lg-6 ta-lg-right mt-24 mt-lg-0">
        <a href={toolsKitUrl} download className="btn btn--line">
          {downloadKitButtonText}
          <Icon name="bottom" className="ml-24 c-red-dark" />
        </a>
      </div>
    </div>
    {/* symbol */}
    <div className="row mt-60 mt-lg-80">
      <div className="col-12">
        <h4>{toolsTitle2}</h4>
      </div>
      <div className="col-12 mt-40">
        <div className="row">
          {tools.map(tool => (
            <div key={tool.id} className="col-6 col-lg-3 mb-32">
              <div className="border-box p-24 pb-40">
                <h5>{tool.title}</h5>
                <figure className="mt-24 ta-center">
                  {tool.logo && <ProjectImage image={tool.logo} />}
                </figure>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Zeo
