import React from 'react'
import Icon from '@c/icon'
import ProjectImage from '@/components/project-image'

const Events = ({
  downloadKitButtonText,

  // events
  eventsTitle,
  eventsKitUrl,
  digitalzoneLogoSymbolsTitle,
  eventsSolidSymbolTitle,
  eventsSolidSymbol,
  eventsLineSymbolTitle,
  eventsLineSymbol,
  eventsLogoTitle,
  eventsLogo,

  // digitalzone meetup
  digitalzoneMeetupLogoTitle,
  digitalzoneMeetupLogoText,
  digitalzoneMeetupLogo,
  digitalzoneMeetupLogoDarkText,
  digitalzoneMeetupLogoDark,
}) => (
  <section>
    <div className="row mt-120 align-items-center">
      <div id="event-assets" className="col-12 col-lg-6">
        <h2>{eventsTitle}</h2>
      </div>
      <div className="col-12 col-lg-6 ta-lg-right mt-24 mt-lg-0">
        <a href={eventsKitUrl} download className="btn btn--line">
          {downloadKitButtonText}
          <Icon name="bottom" className="ml-24 c-red-dark" />
        </a>
      </div>
    </div>
    {/* digitalzone symbol */}
    <div className="row mt-60 mt-lg-80">
      <div className="col-12">
        <h4>{digitalzoneLogoSymbolsTitle}</h4>
      </div>
      <div className="col-12 mt-40">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="border-box p-24 pb-40">
              <h5>{eventsSolidSymbolTitle}</h5>
              <figure className="mt-24 ta-center">
                {eventsSolidSymbol && (
                  <ProjectImage image={eventsSolidSymbol} />
                )}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-3 mt-32 mt-lg-0">
            <div className="border-box p-24 pb-40">
              <h5>{eventsLineSymbolTitle}</h5>
              <figure className="mt-24 ta-center">
                {eventsLineSymbol && <ProjectImage image={eventsLineSymbol} />}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-32 mt-lg-0">
            <div className="border-box p-24 pb-40">
              <h5>{eventsLogoTitle}</h5>
              <figure className="mt-24 ta-center">
                {eventsLogo && <ProjectImage image={eventsLogo} />}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* digitalzone colors */}
    <div className="row mt-40">
      <div className="col-12">
        <div className="row no-gutters">
          <div className="col-6">
            <div className="bg-digitalzone c-white p-8 d-flex align-items-center justify-content-center">
              #403294
            </div>
          </div>
          <div className="col-6">
            <div className="bg-dark c-white p-8 d-flex align-items-center justify-content-center">
              #012535
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* meetup symbol */}
    <div className="row mt-60 mt-lg-80">
      <div className="col-12">
        <h4>{digitalzoneMeetupLogoTitle}</h4>
      </div>
      <div className="col-12 mt-40">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="border-box p-24 pb-40">
              <h5>{digitalzoneMeetupLogoText}</h5>
              <figure className="mt-24 ta-center">
                {digitalzoneMeetupLogo && (
                  <ProjectImage image={digitalzoneMeetupLogo} />
                )}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-32 mt-lg-0">
            <div className="border-box p-24 pb-40 bg-dark c-white">
              <h5>{digitalzoneMeetupLogoDarkText}</h5>
              <figure className="mt-24 ta-center">
                {digitalzoneMeetupLogoDark && (
                  <ProjectImage image={digitalzoneMeetupLogoDark} />
                )}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* meetup colors */}
    <div className="row mt-40">
      <div className="col-12">
        <div className="row no-gutters">
          <div className="col-6">
            <div className="bg-meetup c-white p-8 d-flex align-items-center justify-content-center">
              #403294
            </div>
          </div>
          <div className="col-6">
            <div className="bg-dark c-white p-8 d-flex align-items-center justify-content-center">
              #012535
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Events
